<template>
  <div>MessengerHistory</div>
</template>

<script>
export default {
  name: 'MessengerHistory',
  data() {
    return {
      payload: [
        {
          content:
            'This time your bid for was rejected.\n\
            Reason: Project cancelled.\nDue to changes \
            on client\'s side, this project has been cancelled. \
            We are very sorry that such possibility misses both \
            you and us.\nDon\'t worry - we will send you new bid \
            as soon as we have something matching your portfolio!\n\
            In case you have questions about this, you can always \
            send email to zofia@devotedstudios.com and we will \
            provide you with more details.',
          author: {
            id: 1,
            name: 'Abram Calzoni, Art director',
          },
          date: new Date(),
          edited: true,
        },
        {
          content: 'You have mentioned @Ahmad Botosh in conversation.',
          author: {
            id: 2,
            name: 'Marcus Korsgaard, TAM',
            me: true,
          },
          date: new Date(),
          edited: false,
        },
        {
          content: 'You @Marcus Korsgaard',
          author: {
            id: 2,
            name: 'Marcus Korsgaard, TAM',
          },
          date: new Date(),
          edited: false,
        },
      ],
    };
  },
};
</script>
